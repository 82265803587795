import { Component, Input } from '@angular/core';

@Component({
  selector: 'profile-widget',
  templateUrl: './profile-widget.component.html',
  styleUrl: './profile-widget.component.scss'
})
export class ProfileWidgetComponent {
  @Input () iconName : string;
  @Input () text : string;
  @Input () backgroundType: string;
}
