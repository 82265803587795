import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SimpleTextDialogComponent } from 'src/app/components/simple-text-dialog/simple-text-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UserProfile } from 'src/app/models/profile.model';

@Component({
  selector: 'about-me-field',
  templateUrl: './about-me-field.component.html',
  styleUrl: './about-me-field.component.scss'
})
export class AboutMeFieldComponent implements OnChanges {
  @Input() description : string = '';
  @Input() userInfo : UserProfile;

  editMode: boolean = false;
  loading : boolean = true;

  profileFormGroup: UntypedFormGroup;

  reporter: boolean = false;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private dialog: MatDialog
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.description && this.description){
      this.profileFormGroup = this._formBuilder.group({
        aboutme: [
          { value: this.description, disabled: true },
          [Validators.required, Validators.maxLength(500)],
        ],
      });
    }

    if(changes.userInfo && this.userInfo){
      this.reporter  = this.userInfo.isReporter;
    }
    
    this.loading = false;
  }

  cancel() : void {
    //Disable fields
    this.profileFormGroup.get('aboutme').disable();

    //Reset to old value
    this.profileFormGroup.reset({
      aboutme: this.description
    });

    this.editMode = false;
  }

  edit() : void {
    //Set fields to be enabled
    this.profileFormGroup.get('aboutme').enable();

    this.editMode = true;
  }

  async save(): Promise<void> {
    if(this.profileFormGroup.dirty && this.profileFormGroup.valid && this.reporter) {
      //Have to send birthday with it for some reason atm, TODO: change minimum requirements for updating profile
      var aboutMe = { 
        profile: this.profileFormGroup.get('aboutme').value,
        birthday: this.userInfo.birthDay //tmp, need to change back-end
      };

      await this.authService.updateProfile(JSON.stringify(aboutMe));

      this.profileFormGroup.get('aboutme').disable();

      this.dialog.open<SimpleTextDialogComponent>(SimpleTextDialogComponent, {
        data: {
          title: 'profile.save.popup_title',
          text: 'profile.save.popup_text',
          showButton: false,
        },
      });

      this.authService.loginSilent().then(() => {
        this.editMode = false;
      });
    } else {
      this.cancel();
    }
  }

  getFabStyle(): string {
    if (this.profileFormGroup.dirty && this.profileFormGroup.valid) {
      return 'active';
    } else {
      return '';
    }
  }
}
