import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { StreApperInfo } from 'src/app/models/streapper-info.model';
import { StreappEvent } from 'src/app/models/streappevent.model';
import { StreappEventsService } from 'src/app/services/streapp-events.service';
import { AuthService } from '../../../services/auth.service';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { ReportingDialogComponent } from 'src/app/components/reporting-button/reporting-dialog/reporting-dialog.component';
import { SimpleTextDialogComponent } from 'src/app/components/simple-text-dialog/simple-text-dialog.component';
import { SimpleTextDialogData } from 'src/app/components/simple-text-dialog/models/simple-text-dialog-data.model';
import { ShareDialogComponent } from 'src/app/components/share-dialog/share-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-reporter',
  templateUrl: './reporter.component.html',
  styleUrls: ['./reporter.component.scss'],
})
export class ReporterComponent implements OnInit, OnDestroy {
  loading: boolean = true;
  loadingEvents: boolean = true;
  error: boolean = false;
  errorText: string;
  profile: StreApperInfo;
  events: Array<StreappEvent> = [];
  filteredEvents: Array<StreappEvent> = [];
  userId: string;
  showInfo: boolean = true;
  availableEventTypes: Array<string> = [];
  currentFilter: string = 'showAll';

  private readonly destroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly streappService: StreappEventsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly legacyDialog: MatLegacyDialog,
    private readonly domSanitizer: DomSanitizer,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        this.userId = params['id'];
        this.getStreapperInfoId(this.userId);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  async getStreapperInfoId(id: string): Promise<void> {
    try {
      this.profile = await this.streappService.getStreapperInfoId(id);
      this.profile.banner = this.profile.banner + '?' + new Date().getTime();
      if (!this.profile.isReporter) {
        this.router.navigate(['/users/detail'], {
          queryParams: { id: this.userId },
        });
      }
    } catch (error) {
      this.error = true;
    } finally {
      this.loading = false;
    }

    await this.streappService
      .getReporterEvents(id)
      .then((data: Array<StreappEvent>) => {
        this.events = data;
        this.filteredEvents = data;
        this.availableEventTypes = [
          ...new Set(this.events.map((item) => item.category)),
        ];
      })
      .catch((error) => {
        this.errorText = error;
      })
      .finally(() => {
        this.loadingEvents = false;
      });
  }

  getProfilePicture() {
    if (this.profile.picture) {
      if (this.profile.picture?.startsWith('http')) {
        return this.profile.picture;
      }

      return this.domSanitizer.bypassSecurityTrustUrl(
        'data:image/*;base64,' + this.profile.picture
      );
    }
  }

  setFilter(selectedFilter: string): void {
    this.currentFilter = selectedFilter;
    this.applyFilter();
  }

  applyFilter(): void {
    this.loadingEvents = true;

    if (this.currentFilter == 'showAll') {
      // Show all events when no filter is applied
      this.filteredEvents = this.events;
    } else {
      // Add events that align with filter
      this.filteredEvents = this.events.filter(
        (item) => item.category === this.currentFilter
      );
    }

    this.loadingEvents = false;
  }

  openReportingDialog(): void {
    this.legacyDialog.open<ReportingDialogComponent, any>(ReportingDialogComponent, {
      data: {
        // eventId: this.eventId,
        userId: this.userId,
      },
    });
  }

  openLoginPrompt(): void {
    this.legacyDialog
      .open<SimpleTextDialogComponent, SimpleTextDialogData>(
        SimpleTextDialogComponent,
        {
          data: {
            title: 'overview.login.popup_title_login',
            text: 'promo.loginRequired',
            showButton: true,
            showCancelButton: true,
            buttonCancelText: 'overview.login.popup_cancel',
            buttonText: 'overview.login.popup_button',
          },
        }
      )
      .afterClosed()
      .subscribe((data: boolean) => {
        if (data) {
          // Redirect to login page with current url to redirect back after login.
          this.router.navigate(['/login'], {
            queryParams: { redirect: this.router.url },
            replaceUrl: true,
          });
        }
      });
  }

  share(): void {
    this.dialog.open<ShareDialogComponent>(
      ShareDialogComponent,
      {
        autoFocus: false, // To prevent select state of close button in header
        data: {
          url: window.location.href,
          title: this.profile.name,
          facebook: true,
          x: true
        }
      }
    );
  }

  isLoggedIn(): boolean {
    return this.authService.isAuthenticated();
  }
}
