<!-- Desktop view, with top navigation bar -->
<ng-container *ngIf="!isMobileView && navbarType === 'top'">
  <div class="d-sm-block navbar-height"></div>
  <div class="d-sm-block navbar navbar-desktop">
    <mat-toolbar color="primary" class="upper-nav">
      <mat-toolbar class="desktop-width-limiter margin-top navPrimary">
        <div class="navBrand">
          <img
            [title]="'general.home_navigation' | translate"
            class="nav-logo"
            height="62"
            [alt]="'app_title' | translate | titlecase"
            src="./assets/images/logo.svg"
            routerLink=""
          />
          <img
            [src]="getLogo()"
            [title]="'general.home_navigation' | translate"
            class="nav-logo-name"
            [ngClass]="{ 'blue-logo-filter': applyLogoFilter }"
            [alt]="'app_title' | translate | titlecase"
            routerLink=""
          />
        </div>
        <div class="navUser">
          <!-- Login Button -->
          <button
            *ngIf="!isLoggedIn()"
            mat-flat-button
            class="login-button"
            [title]="'menu.login' | translate"
            (click)="login()"
          >
            {{ "menu.login" | translate }}
          </button>
          <!-- Profile Button/Icon -->
          <img
            *ngIf="isLoggedIn() && router.url != '/profile'"
            [src]="getProfileImage()"
            onerror="this.onerror=null;this.src='/assets/images/user.png';"
            height="16"
            width="16"
            [alt]="'navbar.nb3' | translate | titlecase"
            [title]="'menu.profile' | translate"
            (click)="navigateToProfile()"
          />
          <button
            mat-button
            *ngIf="isLoggedIn() && router.url === '/profile'"
            class="close-profile-button"
            (click)="navigationService.back()"
          >
            <div class="icon">
              <mat-icon>close</mat-icon>
            </div>
          </button>
        </div>
      </mat-toolbar>
    </mat-toolbar>
  </div>
</ng-container>

<!-- Menu Left Sidenav -->
<ng-container *ngIf="!isMobileView && navbarType === 'left-side'">
  <div class="menu-items">
    <ng-container *ngFor="let item of items">
      <!-- Buttons per available route -->
      <button
        mat-flat-button
        #tooltip="matTooltip"
        [matTooltip]="item.title | translate"
        matTooltipPosition="right"
        matTooltipClass="menu-tooltip"
        [aria-tooltip]="item.title | translate"
        [routerLink]="item.routerLink"
        [ngClass]="{ active: item === activeItem }"
        class="nav-singleItem"
      >
        <!-- Selection Line -->
        <div class="selectedLine"></div>
        <!-- 112 Icon -->
        <mat-icon *ngIf="item.routerLink !== '/112'">{{ item.icon }}</mat-icon>
        <img
          src="/assets/images/alarm-light-outline-white.png"
          alt="Alarm Light Outline"
          *ngIf="
            item.routerLink === '/112' && activeItem?.routerLink !== '/112'
          "
        />
        <img
          src="/assets/images/alarm-light-outline-orange.png"
          alt="Alarm Light Outline"
          *ngIf="
            item.routerLink === '/112' && activeItem?.routerLink === '/112'
          "
        />
      </button>
    </ng-container>
    <ng-container *ngFor="let item of getItemsForRole()">
      <!-- Role specific routes -->
      <button
        mat-flat-button
        #tooltip="matTooltip"
        [matTooltip]="item.title | translate"
        matTooltipPosition="right"
        matTooltipClass="menu-tooltip"
        [aria-tooltip]="item.title | translate"
        [id]="item.icon"
        class="nav-singleItem"
        [ngClass]="{ active: item === activeItem }"
        [routerLink]="item.routerLink"
        [title]="item.title | translate"
      >
        <!-- Selection Line -->
        <div class="selectedLine"></div>

        <mat-icon
          color="primary"
          class="icon"
          *ngIf="
            item.icon &&
            (item.icon !== 'account_circle' || !isLoggedIn()) &&
            item.icon !== 'camera_alt'
          "
          >{{ item.icon }}</mat-icon
        >
      </button>
    </ng-container>
  </div>
</ng-container>

<!-- Mobile navigation bar at the bottom -->
<ng-container *ngIf="isMobileView && navbarType === 'mobile'">
  <div class="mobile-bottom-bar">
    <div class="bottom-bar-first-half" (click)="mobileCreateClick()">
      <img src="/assets/images/Logo_halve_ring.png" alt="StreApp plaatsen" />
    </div>
    <div class="bottom-bar-second-half">
      <mat-icon
        [title]="'menu.live' | translate"
        [routerLink]="'/'"
        class="icon-update"
        [class.active]="activeItem?.routerLink === '/'"
        >home</mat-icon
      >
      <mat-icon
        [title]="'menu.kaart' | translate"
        (click)="mobileMapClick()"
        class="icon-map"
        [class.active]="activeItem?.routerLink === '/kaart'"
        >map</mat-icon
      >
      <mat-icon
        [title]="'menu.lokaal' | translate"
        (click)="mobileLocalClick()"
        class="icon-local"
        [class.active]="activeItem?.routerLink === '/lokaal'"
        >my_location</mat-icon
      >
      <span
        [title]="'menu.p2000' | translate"
        [routerLink]="'/112'"
        class="icon-alert"
      >
        <img
          src="/assets/images/alarm-light-outline-white.png"
          alt="Alarm Light Outline"
          *ngIf="activeItem?.routerLink !== '/112'"
        />
        <img
          src="/assets/images/alarm-light-outline-orange.png"
          alt="Alarm Light Outline"
          *ngIf="activeItem?.routerLink === '/112'"
        />
      </span>
      <mat-icon
        [title]="'menu.menu' | translate"
        class="icon-menu"
        [class.active]="sidenav.opened"
        (click)="sidenav.toggle()"
        >more_vert</mat-icon
      >
    </div>
  </div>

  <!-- Menu SideNav for mobile-->
  <div
    class="sidenav-backdrop"
    *ngIf="sidenav.opened"
    (click)="sidenav.toggle()"
  ></div>
  <mat-sidenav
    #sidenav
    (click)="sidenav.toggle()"
    class="nav-mobile"
    [autoFocus]="false"
    [position]="'end'"
  >
    <div class="sidenav-header mat-elevation-z4">
      <span class="header-title">{{
        "menu.menu_items.title" | translate
      }}</span>
      <button
        class="close-ref"
        mat-icon-button
        (click)="sidenav.toggle()"
        [title]="'general.close' | translate | titlecase"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <!-- Links Depending on Role -->
    <span>
      <!-- Profile Button -->
      <button
        *ngIf="isLoggedIn()"
        mat-flat-button
        routerLink="/profile"
        class="nav-singleItem"
        [title]="'menu.menu_items.profile' | translate"
      >
        <img
          class="profile-image-nav"
          [src]="getProfileImage()"
          onerror="this.onerror=null;this.src='/assets/images/user.png';"
          height="24"
          width="24"
          [alt]="'navbar.nb3' | translate | titlecase"
        />
        <span>{{ "menu.menu_items.profile" | translate }}</span>
      </button>
      <!-- Streapp Reporters Button -->
      <button
        mat-flat-button
        routerLink="/reporters"
        class="nav-singleItem"
        [title]="'menu.menu_items.streapp_reporters' | translate"
      >
        <img
          class="profile-image-nav"
          src="/assets/images/logo_reporter_los.svg"
          onerror="this.onerror=null;this.src='/assets/images/user.png';"
          height="24"
          width="24"
          [alt]="'navbar.nb3' | translate | titlecase"
        />
        <span>{{ "menu.menu_items.streapp_reporters" | translate }}</span>
      </button>
      <!-- Info Button -->
      <button
        mat-flat-button
        routerLink="/over-ons"
        class="nav-singleItem"
        [title]="'menu.menu_items.info' | translate"
      >
        <mat-icon color="primary" class="mobile-icon"> info </mat-icon>
        <span>{{ "menu.menu_items.info" | translate }}</span>
      </button>
      <ng-container *ngFor="let item of getItemsForRole()">
        <button
          [id]="item.icon"
          class="nav-singleItem"
          mat-flat-button
          [routerLink]="item.routerLink"
          [title]="'item.title' | translate"
        >
          <mat-icon
            color="primary"
            class="mobile-icon"
            *ngIf="
              item.icon &&
              (item.icon !== 'account_circle' || !isLoggedIn()) &&
              item.icon !== 'camera_alt'
            "
            >{{ item.icon }}</mat-icon
          >
          <span>{{ item.title | translate }}</span>
        </button>
      </ng-container>
    </span>
    <span *ngIf="isLoggedIn()">
      <button
        id="navSideLogout"
        class="nav-singleItem"
        mat-flat-button
        (click)="signout()"
      >
        <mat-icon color="primary" class="mobile-icon">exit_to_app</mat-icon>
        <span>{{ "menu.signout" | translate }}</span>
      </button>
    </span>
    <span *ngIf="!isLoggedIn()">
      <button
        id="navSideLogin"
        class="nav-singleItem"
        mat-flat-button
        (click)="login()"
      >
        <mat-icon color="primary" class="mobile-icon">login</mat-icon>
        <span>{{ "menu.login" | translate }}</span>
      </button>
    </span>
    <hr />
    <span class="socials">
      <a
        href="https://x.com/streapp_com"
        target="_blank"
        class="nav-singleItem"
      >
        <img
          width="24"
          height="24"
          src="/assets/images/socials/X_logo_2023_original.svg"
        />
      </a>
      <a
        href="http://instagram.com/streapp"
        target="_blank"
        class="nav-singleItem"
      >
        <img
          width="24"
          height="24"
          src="/assets/images/socials/Instagram_color.png"
        />
      </a>
      <a
        href="https://www.facebook.com/profile.php?id=100089259057883"
        target="_blank"
        class="nav-singleItem"
      >
        <img
          width="24"
          height="24"
          src="/assets/images/socials/Facebook_color.png"
        />
      </a>
    </span>
  </mat-sidenav>
</ng-container>
