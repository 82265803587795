import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackDialogComponent } from '../feedback-dialog/feedback-dialog.component';

@Component({
  selector: 'feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit  {

  constructor(private readonly dialog: MatDialog) { }
 
  ngOnInit(): void {
  }

  get scrollBarsVisible() {
    return document.body.scrollHeight > document.body.clientHeight;
  }
  

  openFeedbackDialog() {
    this.dialog.open<FeedbackDialogComponent>(FeedbackDialogComponent, {autoFocus: false}).afterClosed().subscribe((data: boolean) => {
      if (data) { 
      }
    });

  }

}
