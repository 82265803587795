<div class="dialog" *ngIf="!send">
    <h2 mat-dialog-title class="header">
        <span class="title">{{ 'feedback.title' | translate }}</span>
        <button class="close-ref" mat-icon-button (click)="close()" [title]="'general.close' | translate | titlecase">
            <mat-icon>clear</mat-icon>
        </button>
    </h2>
    <div class="profile-content" mat-dialog-content>
        <form [formGroup]="feedbackFormGroup">
        <mat-form-field appearance="outline" class="size-xl">
            <mat-label>{{'contact.message_label' | translate}}</mat-label>
            <textarea #message required maxlength="500" autosize rows="3" minRows="3" maxRows="10" matInput formControlName="message"  [placeholder]="'feedback.placeholder' | translate"></textarea>
            <mat-hint align="end">{{message.value?.length || 0}} / 500</mat-hint>
            <mat-error>{{'contact.message_validation' | translate}}</mat-error>
        </mat-form-field>
    </form>
    </div>
    <div mat-dialog-actions class="dialog-buttons-end">
      <button class="close-ref" mat-flat-button (click)="close()" [title]="'general.cancel' | translate">
        {{ 'general.cancel' | translate }}
      </button>
      <button class="close-ref" mat-flat-button [title]="'feedback.send' | translate"
              [color]="feedbackFormGroup.dirty && feedbackFormGroup.valid ? 'accent' : 'primary'"
              [disabled]="!feedbackFormGroup.dirty && !feedbackFormGroup.valid"
              (click)="closeWithConfirm()">
        {{ 'feedback.send' | translate }}
      </button>
    </div>
</div>
<div class="dialog" *ngIf="send">
  <h2 mat-dialog-title class="header">
    {{ 'feedback.confirmed-title' | translate }}
    <button class="close-ref" mat-icon-button (click)="close()" [title]="'general.close' | translate | titlecase">
      <mat-icon>clear</mat-icon>
    </button>
  </h2>
  <div class="profile-content" mat-dialog-content>
    {{ 'feedback.confirmed' | translate }}
  </div>
  <div mat-dialog-actions class="dialog-buttons flex-end">
    <button class="close-ref" mat-flat-button [color]="'primary'" (click)="close()" [title]="'general.close' | translate">
      {{ 'general.close' | translate }}
    </button>
  </div>
</div>
