import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sebbie-info-dialog',
  templateUrl: './sebbie-info-dialog.component.html',
  styleUrl: './sebbie-info-dialog.component.scss'
})
export class SebbieInfoDialogComponent {

  constructor(
    private readonly dialogRef: MatDialogRef<SebbieInfoDialogComponent>
  ) { }

  close() : void {
    this.dialogRef.close();
  }
}
