<div class="profile-image">
  <!-- Banner section -->
  <div class="banner-container">
    <img
      class="banner"
      [src]="profile.banner"
      onerror="this.src='/assets/images/fallback-banner.png';"
      [alt]="'Banner'"
    />
      <div class="fab-background">
        <fab-no-title [icon]="'edit'" (click)="edit()"></fab-no-title>
      </div>
    <!-- Image Section -->
    <div class="image-section">
      <div class="user-image-holder">
        <img
          [src]="getProfilePicture()"
          onerror="this.src = '/assets/images/user.png';"
          class="user-image"
          [alt]="'Afbeelding gebruiker'"
        /> 
      </div>
      <span
        class="username"
        *ngIf="!!profile.name && profile.name !== ' '"
        >{{ profile.name }}</span
      >
      <span
        class="username"
        *ngIf="!profile.name || profile.name === ' '"
        >{{ profile.userName }}</span
      >
      <!-- User Text Image -->
      <img
        *ngIf="userType === 0"
        class="user-logo"
        src="/assets/images/tekst_streapp_white.svg"
        [alt]="'StreApp Reporter'"
      />
      <!-- Reporter Text Image -->
      <img
        *ngIf="userType === 1"
        class="reporter-logo"
        src="/assets/images/streapp-reporter-white-gold.png"
        [alt]="'StreApp Reporter'"
      />
    </div>
  </div>
</div>