<mat-card class="widget">
    <div class="fab-buttons">
        <fab-no-title *ngIf="!editMode" [icon]="'edit'" (click)="edit()"></fab-no-title>
        <fab-no-title *ngIf="editMode" class="close" [icon]="'close'" (click)="cancel()"></fab-no-title>
        <fab-no-title *ngIf="editMode" [style]="getFabStyle()" [icon]="'save'" (click)="save()"></fab-no-title>
    </div>
    <div class="about-content">
        <h1>{{ 'profile.about_me' | translate }}</h1>
        <div class="about-content-container">
            <div class="text" *ngIf="!editMode">
                <span>{{ description }}</span>
            </div>
            <div *ngIf="editMode">
                <form [formGroup]="profileFormGroup">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ "profile.aboutme_label" | translate }}</mat-label>
                        <textarea
                          #aboutme
                          required
                          maxlength="500"
                          autosize
                          rows="3"
                          minRows="3"
                          maxRows="10"
                          formControlName="aboutme"
                          matInput
                          [placeholder]="'profile.aboutme_placeholder' | translate"
                        ></textarea>
                        <span class="character-counter">{{ aboutme.value.length }} / {{ aboutme.maxLength }}</span>
                        <mat-error>{{ "profile.aboutme_validation" | translate }}</mat-error>
                      </mat-form-field>
                </form>
            </div>
        </div>
    </div>
</mat-card>