<div class="dialog">
    <h2 mat-dialog-title class="header">
      <span class="title">{{ 'profile_settings.my_selected_location' | translate }}</span>
      <button class="close-ref" mat-icon-button (click)="close()" [title]="'general.close' | translate | titlecase">
        <mat-icon>clear</mat-icon>
      </button>
    </h2>
    <div mat-dialog-content>
      <div class="description">
        {{ 'profile_settings.select_location_description' | translate }}
      </div>
      <div class="location-text">
          <location-search-field [data]="userProfile.location" (locationChanged)="onCustomLocationChanged($event)"></location-search-field>
      </div>
      <div class="azure-map">
        <azure-map #mapelement>
        </azure-map>
      </div>
    </div>
    <div mat-dialog-actions class="dialog-buttons-end">
      <button class="close-ref cancel" mat-flat-button color="primary" [title]="'general.cancel' | translate | titlecase" (click)="close()">
        {{ 'general.cancel' | translate}}
      </button>
      <button class="close-ref confirm" mat-flat-button color="accent" [disabled]="!locationChanged" [title]="'general.save' | translate | titlecase" (click)="save()">
        {{ 'general.save' | translate }}
      </button>
    </div>
</div>