<div class="dialog">
    <h2 mat-dialog-title class="header">
      <span class="title">{{ 'sebbie.info' | translate }}</span>
      <button class="close-ref" mat-icon-button (click)="close()" [title]="'general.close' | translate | titlecase">
        <mat-icon>clear</mat-icon>
      </button>
    </h2>
    <div mat-dialog-content>
        <div class="section">
            <div class="sebbie-title">
                <mat-icon>info</mat-icon><h1> {{ 'sebbie.title' | translate }}</h1>
            </div>
            <div class="text">
                {{ 'sebbie.info_intro_text' | translate }}
            </div>
        </div>
        <div class="section">
            <h2>
                {{ 'sebbie.recieve_title' | translate }}
            </h2>
            <div class="text">
                {{ 'sebbie.recieve_text' | translate }}
            </div>
        </div>
        <div class="section">
            <h2>
                {{ 'sebbie.usedfor_title' | translate }}
            </h2>
            <div class="text">
                {{ 'sebbie.usedfor_text' | translate }}
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="dialog-button-end">
      <button class="close-ref" mat-flat-button color="primary" [title]="'general.close' | translate | titlecase" (click)="close()">
        {{ 'general.close' | translate }}
      </button>
    </div>
</div>