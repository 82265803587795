<div class="counter-padding">
    <div class="counter">
        <fab-no-title class="info" [icon]="'info'" (click)="openInfoDialog()"></fab-no-title>
        <div class="point-container-wrap">
            <div class="point-container monthly-points">
                <span class="number">+{{ monthlySebbiePoints }}</span>
                <span class="text">{{ 'sebbie.new' | translate }}</span>
            </div>
            <div class="seperation-line"></div>
            <div class="point-container total-points">
                <span class="number">{{ sebbiePoints }}</span>
                <span class="text">{{ 'sebbie.total' | translate }}</span>
            </div>
        </div>
        <span class="your-sebbie">{{ 'sebbie.your-sebbie' | translate }}</span>
    </div>
</div>