import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { StreApperInfo } from 'src/app/models/streapper-info.model';
import { MatDialog } from '@angular/material/dialog';
import { UpdateBannerDialogComponent } from '../update-banner-dialog/update-banner-dialog.component';

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrl: './banner.component.scss'
})
export class BannerComponent implements OnChanges {
  @Input() profile : StreApperInfo;
  @Input() userType : number = 0; //0 = Standard User, 1 = StreappReporter, 2 = Prospect

  reporter: boolean;

  constructor(
    private readonly domSanitizer: DomSanitizer,
    private dialog: MatDialog
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.profile && this.profile){
      this.reporter = this.profile.isReporter;
    }
  }

  getProfilePicture() {
    if (this.profile.picture) {
      if (this.profile.picture?.startsWith('http')) {
        return this.profile.picture;
      }

      return this.domSanitizer.bypassSecurityTrustUrl(
        'data:image/*;base64,' + this.profile.picture
      );
    }
  }

  edit() : void {
    //open banner & profile edit dialog
    this.dialog.open<UpdateBannerDialogComponent>(
      UpdateBannerDialogComponent,
      {
        autoFocus: false, // To prevent select state of close button in header
        panelClass: 'medium',
        data: {
          profile: this.profile
        },
      }
    );
  }
}
