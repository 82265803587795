import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { AuthService } from 'src/app/services/auth.service';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { StreApperInfo } from 'src/app/models/streapper-info.model';
import { SimpleTextDialogComponent } from 'src/app/components/simple-text-dialog/simple-text-dialog.component';

@Component({
  selector: 'update-banner-dialog',
  templateUrl: './update-banner-dialog.component.html',
  styleUrl: './update-banner-dialog.component.scss'
})
export class UpdateBannerDialogComponent implements OnInit {
  loading: boolean = true;

  errorText: string;
  originalProfileUrl: any;
  originalBannerUrl: any;
  profileUrl: any;
  bannerUrl: any;

  profileFormGroup : UntypedFormGroup

  profileImageFile: NgxFileDropEntry[] = [];
  bannerFile: NgxFileDropEntry[] = [];
  
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private legacyDialog: MatLegacyDialog,
    private readonly dialogRef: MatDialogRef<UpdateBannerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: { 
      profile : StreApperInfo
    },

  ) { 
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.profileFormGroup = this._formBuilder.group({
      profileImageFile: [''],
      bannerFile: [''],
    });

    if (this.data.profile.picture) {
      this.originalProfileUrl = this.authService.pictureUrl;
      this.profileUrl = this.authService.pictureUrl;
    }
    if (this.data.profile.banner) {
      this.originalBannerUrl = this.data.profile.banner;
      this.bannerUrl = this.data.profile.banner;
    }
  }

  cancel() : void {
    //Reset to previous images
    this.bannerUrl = this.originalBannerUrl;
    this.removeFile(0, "banner");

    this.profileUrl = this.originalProfileUrl;
    this.removeFile(0, "profile");
  }

  close() : void {
    this.cancel();
    this.dialogRef.close(false);
  }

  async save() : Promise<void> {
    var isError: boolean = false;
    // Upload Profile Image
    var firstFile = this.profileImageFile[0];
    if (firstFile) {
      const fileEntry = firstFile.fileEntry as FileSystemFileEntry;
      await fileEntry.file(async (file: File) => {
        //Upload file and save profileUrl
        const formData = new FormData();
        formData.append('fileKey', file, firstFile.relativePath);

        try {
          await this.authService.uploadProfilePicture(formData);
        } catch (error) {
          isError = true;
          this.legacyDialog.open<SimpleTextDialogComponent>(
            SimpleTextDialogComponent,
            {
              data: {
                title: 'Fout bij opslaan van afbeelding',
                text: 'De afbeelding is te groot. Het bestand mag maximaal 1Mb zijn.',
                showButton: false,
              },
            }
          );
        }
      });
    }
    // Upload Banner
    var secondFile = this.bannerFile[0];
    if (secondFile) {
      const fileEntry = secondFile.fileEntry as FileSystemFileEntry;
      await fileEntry.file(async (file: File) => {
        //Upload file and save bannerUrl
        const formData = new FormData();
        formData.append('fileKey', file, secondFile.relativePath);

        try {
          await this.authService.uploadBanner(formData);
        } catch (error) {
          isError = true;
          this.legacyDialog.open<SimpleTextDialogComponent>(
            SimpleTextDialogComponent,
            {
              data: {
                title: 'Fout bij opslaan van afbeelding',
                text: 'De afbeelding is te groot. Het bestand mag maximaal 1Mb zijn.',
                showButton: false,
              },
            }
          );
        }
      });
    }

    if (isError) {
      close();
      return;
    }

    this.dialogRef.close();

    this.legacyDialog.open<SimpleTextDialogComponent>(SimpleTextDialogComponent, {
      data: {
        title: 'profile.save.popup_title',
        text: 'profile.save.popup_text',
        showButton: false,
      },
    });

    this.authService.loginSilent().then(() => {
      this.profileUrl = this.authService.pictureUrl;
    });
  }

  removeBanner(): void {
    this.authService.removeBanner().then(() => {
      this.bannerUrl = '';
      this.bannerFile = [];
      this.originalBannerUrl = null;
    });
  }

  removePicture(): void {
    this.authService.removeProfilePicture().then(() => {
      this.profileUrl = '';
      this.profileImageFile = [];
      this.originalProfileUrl = null;
    });
  }

  public dropped(files: NgxFileDropEntry[], url: string): void {
    if (url === 'profile') {
      for (const droppedFile of files) {
        if (droppedFile.fileEntry.isFile) {
          this.profileImageFile = []; //Empty list (limit of 1 image)
          this.profileImageFile.push(droppedFile);
          this.profileFormGroup.controls['profileImageFile'].setValue(
            droppedFile.relativePath
          );

          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          const reader = new FileReader();

          fileEntry.file((file) => {
            reader.readAsDataURL(file);
            reader.onload = () => {
              this.profileUrl = reader.result;
            };
          });
        }
      }
    } else if (url === 'banner') {
      for (const droppedFile of files) {
        if (droppedFile.fileEntry.isFile) {
          this.bannerFile = []; //Empty list (limit of 1 image)
          this.bannerFile.push(droppedFile);
          this.profileFormGroup.controls['bannerFile'].setValue(
            droppedFile.relativePath
          );

          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          const reader = new FileReader();

          fileEntry.file((file) => {
            reader.readAsDataURL(file);
            reader.onload = () => {
              this.bannerUrl = reader.result;
            };
          });
        }
      }
    }
  }

  removeFile(i: number, url: string): void {
    if (url === 'profile') {
      this.profileImageFile.splice(i, 1);
      this.profileUrl = this.originalProfileUrl;
    } else {
      this.bannerFile.splice(i, 1);
      this.bannerUrl = this.originalBannerUrl;
    }
  }

}
