import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserProfile } from 'src/app/models/profile.model';
import { AuthService } from 'src/app/services/auth.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SimpleTextDialogComponent } from 'src/app/components/simple-text-dialog/simple-text-dialog.component';

@Component({
  selector: 'profile-userinfo-field',
  templateUrl: './profile-userinfo-field.component.html',
  styleUrl: './profile-userinfo-field.component.scss'
})
export class ProfileUserinfoFieldComponent implements OnChanges{
  @Input() userInfo : UserProfile;

  loading : boolean = true;
  editMode: boolean = false;
  profileFormGroup : UntypedFormGroup;
  reporter : boolean = false;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private readonly authService: AuthService,
    private dialog: MatDialog
  ){}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.userInfo && this.userInfo)
    {
      this.profileFormGroup = this._formBuilder.group({
        firstname: [{ value: this.userInfo.firstName, disabled: true }, [Validators.maxLength(150)]],
        lastname: [{ value: this.userInfo.lastName, disabled: true }, [Validators.maxLength(150)]],
        email: [{ value: this.userInfo.email, disabled: true }],
        birthday: [{ value: this.userInfo.birthDay, disabled: true }],
      });
      
      this.reporter = this.userInfo.isReporter

      this.loading = false;
    }
  }

  cancel() : void {
    //Disable fields
    this.profileFormGroup.get('birthday').disable();

    if (this.reporter) {
      this.profileFormGroup.get('firstname').disable();
      this.profileFormGroup.get('lastname').disable();
    }

    //Reset to old values
    this.profileFormGroup.reset({
      firstname: this.userInfo.firstName,
      lastname: this.userInfo.lastName,
      email: this.userInfo.email,
      birthday: this.userInfo.birthDay
    });

    this.editMode = false;
  }

  edit() : void {
    //Set fields to be enabled
    this.profileFormGroup.get('birthday').enable();

    if (this.reporter) {
      this.profileFormGroup.get('firstname').enable();
      this.profileFormGroup.get('lastname').enable();
    }

    this.editMode = true;
  }

  async save(): Promise<void> {
    if(this.profileFormGroup.dirty && this.profileFormGroup.valid) {
      var profile;
      if (this.reporter) {
        profile = {
          firstname: this.profileFormGroup.get('firstname').value,
          lastname: this.profileFormGroup.get('lastname').value,
          birthday: this.profileFormGroup.get('birthday').value,
        };
      } else {
        profile = {
          birthday: this.profileFormGroup.get('birthday').value,
        };
      }

      await this.authService.updateProfile(JSON.stringify(profile));

      this.profileFormGroup.get('firstname').disable();
      this.profileFormGroup.get('lastname').disable();
      this.profileFormGroup.get('birthday').disable();

      this.dialog.open<SimpleTextDialogComponent>(SimpleTextDialogComponent, {
        data: {
          title: 'profile.save.popup_title',
          text: 'profile.save.popup_text',
          showButton: false,
        },
      });

      this.authService.loginSilent().then(() => {
        this.editMode = false;
      }).catch((error : any) => {
        console.log(error)
      });
    } else {
      this.cancel();
    }
  }

  getFabStyle(): string {
    if (this.profileFormGroup.dirty && this.profileFormGroup.valid) {
      return 'active';
    } else {
      return '';
    }
  }
}
