import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { UserProfile } from 'src/app/models/profile.model';
import { StreApperInfo } from 'src/app/models/streapper-info.model';
import { StreappEvent } from 'src/app/models/streappevent.model';
import { AuthService } from 'src/app/services/auth.service';
import { StreappEventsService } from 'src/app/services/streapp-events.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { DeleteAccountDialogComponent } from '../_components/delete-account-dialog/delete-account-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ContactDialogComponent } from 'src/app/components/contact-dialog/contact-dialog.component';
import { ShareDialogComponent } from 'src/app/components/share-dialog/share-dialog.component';

@Component({
  selector: 'profile-reporter',
  templateUrl: './profile-reporter.component.html',
  styleUrl: './profile-reporter.component.scss'
})
export class ProfileReporterComponent {
  amountOfStreappsPosted : number = 0;
  monthlySebbiePoints : number = 0;
  totalSebbiePoints : number = 0;
  loading: boolean = true;
  loadingEvents: boolean = true;
  error: boolean = false;
  errorText: string;
  profile: StreApperInfo;
  userInfo: UserProfile;
  userId: string;
  events: Array<StreappEvent> = [];
  filteredEvents: Array<StreappEvent> = [];
  availableEventTypes: Array<string> = [];
  currentFilter: string = 'showAll';

  private readonly destroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly streappService: StreappEventsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly clipboard: Clipboard,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if(this.isLoggedIn() && this.authService.isReporter)
    {
      //user info (username, firstname etc.)
      this.authService
      .getClaimData(true)
      .then((data) => {
        this.userInfo = data;
      }).catch((error) => {
        this.errorText = error;
      })

      //user profile content (banner, streapps etc.)
      this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        this.userId = this.authService.userId;
        this.getStreapperInfoId(this.userId);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  async getStreapperInfoId(id: string): Promise<void> {
    try {
      this.profile = await this.streappService.getStreapperInfoId(id);
      if(this.profile.banner){
        this.profile.banner = this.profile.banner + '?' + new Date().getTime();
      }
      if (!this.profile.isReporter) {
        this.router.navigate(['/users/detail'], {
          queryParams: { id: this.userId },
        });
      }
    } catch (error) {
      this.error = true;
    } finally {
      this.loading = false;
    }

    await this.streappService
      .getReporterEvents(id)
      .then((data: Array<StreappEvent>) => {
        this.events = data;
        this.filteredEvents = data;
        this.availableEventTypes = [
          ...new Set(this.events.map((item) => item.category)),
        ];
      })
      .catch((error) => {
        this.errorText = error;
      })
      .finally(() => {
        this.loadingEvents = false;
      });
  }

  setFilter(selectedFilter: string): void {
    this.currentFilter = selectedFilter;
    this.applyFilter();
  }

  applyFilter(): void {
    this.loadingEvents = true;

    if (this.currentFilter == 'showAll') {
      // Show all events when no filter is applied
      this.filteredEvents = this.events;
    } else {
      // Add events that align with filter
      this.filteredEvents = this.events.filter(
        (item) => item.category === this.currentFilter
      );
    }

    this.loadingEvents = false;
  }


  contactAdmin() : void {
    this.dialog.open<ContactDialogComponent>(
      ContactDialogComponent,
      {
        autoFocus: false, // To prevent select state of close button in header
      }
    );
  }

  logout() : void {
    this.authService.signout();
  }

  share() : void {
    this.dialog.open<ShareDialogComponent>(
      ShareDialogComponent,
      {
        autoFocus: false, // To prevent select state of close button in header
        data: {
          url: 'https://streapp.com/reporters/detail?id=' + this.profile.id,
          title: this.profile.name,
          facebook: true,
          x: true
        }
      }
    );
  }

  deleteAccount() : void {
    this.dialog.open<DeleteAccountDialogComponent>(
      DeleteAccountDialogComponent,
      {
        autoFocus: false, // To prevent select state of close button in header
      }
    );
  }

  isLoggedIn(): boolean {
    return this.authService.isAuthenticated();
  }
}
