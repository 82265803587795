<mat-card class="widget">
    <div *ngIf="!loading">
        <div class="fab-buttons">
            <fab-no-title *ngIf="!editMode" [icon]="'edit'" (click)="edit()"></fab-no-title>
            <fab-no-title *ngIf="editMode" class="close" [icon]="'close'" (click)="cancel()"></fab-no-title>
            <fab-no-title *ngIf="editMode" [style]="getFabStyle()" [icon]="'save'" (click)="save()"></fab-no-title>
        </div>
        <div class="user-content">
            <h1>{{ 'profile.profile_userinfo_label' | translate }}</h1>
            <div class="user-content-container">
                <div class="info" *ngIf="!editMode">
                    <span style="padding-top: 5px;" >{{ 'profile.username_label' | translate}}: {{userInfo?.userName}}</span>
                    <span>{{ 'profile.email_label' | translate}}: {{userInfo?.email}}</span>
                    <span>{{ 'profile.firstname_label' | translate}}: {{userInfo?.firstName}}</span>
                    <span>{{ 'profile.lastname_label' | translate}}: {{userInfo?.lastName}}</span>
                    <span>{{ 'profile.birthday_label' | translate}}: {{userInfo?.birthDay}}</span>
                </div>
                <div class="user-content-form" *ngIf="editMode">
                    <form [formGroup]="profileFormGroup">
                        <div>
                        <!-- Email -->
                        <mat-form-field appearance="outline" class="size-s">
                            <mat-label>{{ "profile.email_label" | translate }}</mat-label>
                            <input
                              #email
                              matInput
                              formControlName="email"
                              [placeholder]="'profile.email_placeholder' | translate"
                            />
                          </mat-form-field>
    
                          <!-- Firstname -->
                          <mat-form-field appearance="outline" class="size-s" *ngIf="reporter">
                            <mat-label>{{ "profile.firstname_label" | translate }}</mat-label>
                            <input
                              #firstname
                              matInput
                              formControlName="firstname"
                              [placeholder]="'profile.firstname_placeholder' | translate"
                              required
                              maxlength="150"
                            />
                            <mat-error>{{
                              "profile.firstname_validation" | translate
                            }}</mat-error>
                          </mat-form-field>
                  
                          <!-- Lastname -->
                          <mat-form-field appearance="outline" class="size-s" *ngIf="reporter">
                            <mat-label>{{ "profile.lastname_label" | translate }}</mat-label>
                            <input
                              #lastname
                              matInput
                              formControlName="lastname"
                              [placeholder]="'profile.lastname_placeholder' | translate"
                              required
                              maxlength="150"
                            />
                            <mat-error>{{ "profile.lastname_validation" | translate }}</mat-error>
                          </mat-form-field>
                  
                          <!-- Birthday -->
                          <mat-form-field appearance="outline" class="size-s">
                            <mat-label>{{ "profile.birthday_label" | translate }}</mat-label>
                            <input
                              #birthday
                              matInput
                              type="date"
                              formControlName="birthday"
                              [placeholder]="'profile.birthday_placeholder' | translate"
                              required
                            />
                            <mat-error>{{ "profile.birthday_validation" | translate }}</mat-error>
                          </mat-form-field>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</mat-card>