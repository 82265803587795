<div class="dialog" *ngIf="!!data">
    <h2 mat-dialog-title class="header">
      <span class="title">{{ 'profile.profile_images.dialog_label' | translate }}</span>
      <button class="close-ref" mat-icon-button (click)="close()" [title]="'general.close' | translate | titlecase">
        <mat-icon>clear</mat-icon>
      </button>
    </h2>
    <div mat-dialog-content>
        <div class="dialog-description">
            {{ 'profile.profile_images.dialog_description' | translate }}
        </div>
        <div class="image-section">
            <h2>{{ 'profile.profile_images.profile_label' | translate }}</h2>
            <h3>{{ 'profile.profile_images.profile_upload_description' | translate }}</h3>
            <div class="user-image-holder">
                <img
                    [src]="profileUrl"
                    onerror="this.src = '/assets/images/user.png';"
                    class="user-image"
                    [alt]="'Afbeelding gebruiker'"
                /> 
            </div>
            <div class="image-dropzone">
                <ngx-file-drop
                    (onFileDrop)="dropped($event, 'profile')"
                    [multiple]="false"
                    [accept]="'image/*'"
                    class="profile-file-drop"
                >
                    <ng-template
                        ngx-file-drop-content-tmp
                        let-openFileSelector="openFileSelector"
                    >
                        <!-- Upload Image Button -->
                        <button mat-flat-button class="upload-button" (click)="openFileSelector()" [title]="'profile.profile_images.profile_upload' | translate">
                            <mat-icon>cloud_upload</mat-icon><span>{{ 'profile.profile_images.profile_upload' | translate }}</span>
                        </button>
                    </ng-template>
                </ngx-file-drop>
                <!-- Delete Image -->
                <button mat-flat-button class="remove-button" (click)="profileImageFile.length > 0 ? removeFile(i, 'profile') : removePicture()" [disabled]="!profileUrl" [title]="'profile.remove-picture' | translate">
                    <mat-icon>delete</mat-icon><span>{{ 'profile.profile_images.remove_current_image' | translate }}</span>
                </button>
            </div>
        </div>
        <div class="banner-section">
            <h2>{{ 'profile.profile_images.banner_label' | translate }}</h2>
            <h3>{{ 'profile.profile_images.banner_upload_description' | translate }}</h3>
            <div class="banner-holder">
                <img
                    class="banner"
                    [src]="bannerUrl"
                    onerror="this.src='/assets/images/fallback-banner.png';"
                    [alt]="'Banner'"
                />
            </div>
            <div class="banner-dropzone">
                <ngx-file-drop
                    (onFileDrop)="dropped($event, 'banner')"
                    [multiple]="false"
                    [accept]="'image/*'"
                    class="file-drop"
                >
                    <ng-template
                        ngx-file-drop-content-tmp
                        let-openFileSelector="openFileSelector"
                    >
                        <!-- Upload Banner Button -->
                        <button mat-flat-button class="upload-button" (click)="openFileSelector()" [title]="'profile.profile_images.banner_upload' | translate">
                            <mat-icon>cloud_upload</mat-icon><span>{{ 'profile.profile_images.banner_upload' | translate }}</span>
                        </button>
                    </ng-template>
                </ngx-file-drop>
                <!-- Delete banner -->
                <button mat-flat-button class="remove-button" (click)="bannerFile.length > 0 ? removeFile(i, 'banner') : removeBanner()" [disabled]="!bannerUrl" [title]="'profile.remove-banner' | translate">
                    <mat-icon>delete</mat-icon><span>{{ 'profile.profile_images.remove_current_image' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="dialog-buttons-end">
      <button class="close-ref cancel" mat-flat-button color="primary" [title]="'general.cancel' | translate | titlecase" (click)="close()">
        {{ 'general.cancel' | translate}}
      </button>
      <button class="close-ref confirm" mat-flat-button color="accent" [disabled]="bannerFile.length === 0 && profileImageFile.length === 0" [title]="'general.save' | translate | titlecase" (click)="save()">
        {{ 'general.save' | translate}}
      </button>
    </div>
</div>