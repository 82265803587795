import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile/profile.component';
import { MyStreAppsComponent } from './_components/my-streapps/my-streapps.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ProfileSubnavComponent } from './_components/profile-subnav/profile-subnav.component';
import { ProfileSettingsComponent } from './profile-settings/profile-settings.component';
import { ProfileReporterComponent } from './profile-reporter/profile-reporter.component';
import { WideCounterButtonComponent } from './_components/wide-counter-button/wide-counter-button.component';
import { ProfileLocationFieldComponent } from './_components/profile-location-field/profile-location-field.component';
import { BannerComponent } from './_components/banner/banner.component';
import { ProfileUserinfoFieldComponent } from './_components/profile-userinfo-field/profile-userinfo-field.component';
import { AboutMeFieldComponent } from './_components/about-me-field/about-me-field.component';
import { UpdateBannerDialogComponent } from './_components/update-banner-dialog/update-banner-dialog.component';
import { SebbieCounterComponent } from './_components/sebbie-counter/sebbie-counter.component';
import { SebbieInfoDialogComponent } from './_components/sebbie-info-dialog/sebbie-info-dialog.component';
import { UpdateLocationDialogComponent } from './_components/update-location-dialog/update-location-dialog.component';
import { DeleteAccountDialogComponent } from './_components/delete-account-dialog/delete-account-dialog.component';


@NgModule({
  declarations: [
    ProfileComponent, 
    MyStreAppsComponent, 
    ProfileSubnavComponent, 
    ProfileSettingsComponent, 
    ProfileReporterComponent, 
    WideCounterButtonComponent, 
    ProfileLocationFieldComponent, 
    BannerComponent, 
    ProfileUserinfoFieldComponent,
    AboutMeFieldComponent,
    UpdateBannerDialogComponent,
    SebbieCounterComponent,
    SebbieInfoDialogComponent,
    UpdateLocationDialogComponent,
    DeleteAccountDialogComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    NgxFileDropModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProfileModule { }
