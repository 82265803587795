import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { SimpleTextDialogData } from 'src/app/components/simple-text-dialog/models/simple-text-dialog-data.model';
import { SimpleTextDialogComponent } from 'src/app/components/simple-text-dialog/simple-text-dialog.component';
import { AppConfigService } from '../../services/app-config.service';
import { AuthService } from '../../services/auth.service';
import { DeviceService } from '../../services/device.service';
import { HelpBoxService } from '../../services/help-box.service';
import { MenuItem } from './_models/menu-items.interface';
import { NavigationService } from 'src/app/services/navigation-end.service';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() navbarType: string;

  applyLogoFilter: boolean = false;
  activeItem: MenuItem;
  menuItem: boolean = false;
  profileItem: boolean = false;
  forceHideSubmenu: boolean = false;
  items: Array<MenuItem> = [
    {
      title: 'menu.live',
      routerLink: '/',
      icon: 'home',
      roles: [],
    },
    {
      title: 'menu.kaart',
      routerLink: '/kaart',
      icon: 'map',
      roles: [],
    },
    {
      title: 'menu.lokaal',
      routerLink: '/lokaal',
      icon: 'my_location',
      roles: [],
    },
    {
      title: 'menu.p2000',
      routerLink: '/112',
      icon: 'near_me',
      roles: [],
    },
    {
      title: 'menu.menu_items.streapp_reporters',
      routerLink: '/reporters',
      icon: 'groups',
      roles: [],
    },
    {
      title: 'menu.create_event',
      routerLink: '/create-event',
      icon: 'add_box',
      roles: [],
    },
    {
      title: 'menu.menu_items.info',
      routerLink: '/info',
      icon: 'info',
      roles: [],
    },
  ];

  role_menu_items: Array<MenuItem> = [
    {
      title: 'menu.menu_items.ads',
      routerLink: '/create-ad',
      icon: 'show_chart',
      roles: ['Admin', 'Ads'],
    },
  ];

  mobile_menu_items: Array<MenuItem> = [
    {
      title: 'menu.menu_items.profile',
      routerLink: '/profile',
      icon: 'account_circle',
      roles: [],
    },
  ];

  isMobileView: boolean = false;

  readonly destroy$: Subject<Boolean> = new Subject();

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private readonly configService: AppConfigService,
    private readonly deviceService: DeviceService,
    private readonly helpBox: HelpBoxService,
    private readonly translate: TranslateService,
    private navigationService: NavigationService
  ) {}

  ngOnInit() {
    this.deviceService.isMobileView
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isMobileView = data;
      });

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        let nav: NavigationEnd = val;
        this.profileItem = false;
        this.menuItem = false;
        this.activeItem = null;
        this.checkActiveNavigation(nav.url.split('?')[0]);
      }
    });

    this.checkActiveNavigation(this.router.url.split('?')[0]);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  checkActiveNavigation(url: string): void {
    switch (url) {
      case '/': {
        this.activeItem = this.items[0];
        break;
      }
      case '/lokaal': {
        this.activeItem = this.items[2];
        break;
      }
      case '/kaart': {
        this.activeItem = this.items[1];
        break;
      }
      case '/112': {
        this.activeItem = this.items[3];
        break;
      }
      case '/112/detail': {
        this.activeItem = this.items[3];
        break;
      }
      case '/reporters': {
        this.activeItem = this.items[4];
        break;
      }
      case '/create-event': {
        this.activeItem = this.items[5];
        break;
      }
      case '/info': {
        this.activeItem = this.items[6];
        break;
      }
      case '/create-ad': {
        this.activeItem = this.role_menu_items[0];
        break;
      }
      case '/profile': {
        this.profileItem = true;
        break;
      }
      case '/my': {
        this.profileItem = true;
        break;
      }
      case '/profile-settings': {
        this.profileItem = true;
        break;
      }
      case 'create-event': {
        break;
      }
      default: {
        this.menuItem = true;
      }
    }
  }

  getItemsForRole() {
    const roles = this.authService.roles;
    return this.role_menu_items.filter((x) => {
      if (x.roles.length == 0) {
        return true;
      }
      var found = false;
      roles.forEach((r) => {
        if (x.roles.includes(r)) {
          found = true;
        }
      });

      return found;
    });
  }

  getLogo() {
    if (this.authService.isAds) {
      this.applyLogoFilter = false;
      return './assets/images/tekst_streapp_ads_white.svg';
    } else if (this.authService.isReporter) {
      this.applyLogoFilter = false;
      return './assets/images/tekst_streapp_reporter_white_01.svg';
    } else {
      this.applyLogoFilter = true;
      return './assets/images/tekst_streapp_white.svg';
    }
  }

  getProfileImage() {
    return this.authService.pictureUrl;
  }

  login() {
    this.authService.login();
    return;
  }

  getName(): string {
    return this.authService.userName;
  }

  async signout() {
    this.dialog
      .open<SimpleTextDialogComponent, SimpleTextDialogData>(
        SimpleTextDialogComponent,
        {
          data: {
            title: 'auth.signout.popup_title',
            text: 'auth.signout.popup_text',
            showButton: true,
            showCancelButton: true,
            buttonCancelText: 'auth.signout.popup_cancel',
            buttonText: 'auth.signout.popup_button',
          },
        }
      )
      .afterClosed()
      .subscribe((data: boolean) => {
        if (data) {
          this.authService.signout();
        }
      });
  }

  navigateToProfile(): void {
    if (this.authService.isReporter) {
      this.router.navigate(['/profile-reporter']);
    } else {
      this.router.navigate(['/profile']);
    }
  }

  isLoggedIn(): boolean {
    return this.authService.isAuthenticated();
  }

  mobileMapClick(): void {
    this.translate.get('help.map-not-available').subscribe((text) => {
      this.helpBox.openBox(text, true);
    });
  }

  mobileLocalClick(): void {
    this.translate.get('help.local-not-available').subscribe((text) => {
      this.helpBox.openBox(text, true);
    });
  }

  mobileCreateClick(): void {
    this.translate.get('help.create-not-available').subscribe((text) => {
      this.helpBox.openBox(text, true);
    });
  }
}
