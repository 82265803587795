import { Component, Input } from '@angular/core';

@Component({
  selector: 'wide-counter-button',
  templateUrl: './wide-counter-button.component.html',
  styleUrl: './wide-counter-button.component.scss'
})
export class WideCounterButtonComponent {
  @Input() count: number;
  @Input() text: string;   
}
