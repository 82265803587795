import { Component, Input } from '@angular/core';

@Component({
  selector: 'fab-no-title',
  templateUrl: './fab-no-title.component.html',
  styleUrl: './fab-no-title.component.scss'
})
export class FabNoTitleComponent {
  @Input() routerLink: string;
  @Input() icon: string;
  @Input() style: string;
}
