import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrl: './share-dialog.component.scss'
})
export class ShareDialogComponent implements OnInit {
  url : string = this.data.url;
  title : string = this.data.title;
  instagram : boolean = false;
  facebook : boolean = false;
  x : boolean = false;
  copied : boolean = false;

  constructor(
    private readonly dialogRef: MatDialogRef<ShareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: { 
      url : string, 
      title : string, 
      instagram : boolean, 
      facebook : boolean, 
      x : boolean 
    },
    private readonly clipboard : Clipboard
  ){}

  ngOnInit(): void {
    if(this.data.instagram === true){
      this.instagram = this.data.instagram;
    }
    if(this.data.facebook === true){
      this.facebook = this.data.facebook;
    }
    if(this.data.x === true){
      this.x = this.data.x;
    }
  }

  close() : void {
    this.dialogRef.close();
  }

  share(type : string) : void {
    switch (type) {
      case 'instagram':
        this.shareOnInstagram();
        break;
      case 'facebook':
        this.shareOnFacebook();
        break;
      case 'x':
      this.shareOnX();
      break;

      default:
        break;
    }
  }

  shareOnInstagram() : void {
    //Share link doesn't seem to exist for instagram (2024)
    window.open('https://www.instagram.com/');
  }

  shareOnFacebook() : void {
    window.open('https://www.facebook.com/sharer.php?u=' + this.url);
  }

  shareOnX() : void {
    window.open('https://x.com/share?url=' + this.url + '&text=' + this.title);
  }

  copy() : void {
    this.clipboard.copy(this.url);
    this.copied = true;

    // Reset text button after 2 seconds
    setTimeout(() => {
      this.copied = false;
    }, 2000);
  }
}
