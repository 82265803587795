import { isPlatformServer } from '@angular/common';
import {
  Component,
  OnInit,
  inject,
  Inject,
  PLATFORM_ID,
  DestroyRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { DeviceService } from './services/device.service';
import { RobotsService } from './services/robots.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Streapp-Website';
  visible: boolean = false;
  isMobileDevice: boolean = false;
  isMobileView: boolean = false;

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private readonly deviceService: DeviceService,
    private readonly robots: RobotsService,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {}

  async ngOnInit(): Promise<void> {
    this.robots.setRobots(true);
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((events) => events instanceof NavigationEnd),
        map((evt) => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((x) =>
        x.hidelayout === true ? (this.visible = false) : (this.visible = true)
      );

    this.deviceService.isMobileView
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: boolean) => {
        this.isMobileView = data;
      });

    if (isPlatformServer(this._platformId)) {
      return;
    }

    if (window.location.href.indexOf('/video') <= -1) {
      this.isMobileDevice = this.deviceService.isAndroidDevice;
    }
  }

  continueToWebsite(): void {
    this.isMobileDevice = false;
  }
}
