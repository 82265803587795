<div class="dialog">
    <h2 mat-dialog-title class="header">
      <span class="title">{{ 'profile.delete_account.header' | translate }}</span>
      <button class="close-ref" mat-icon-button (click)="close()" [title]="'general.close' | translate | titlecase">
        <mat-icon>clear</mat-icon>
      </button>
    </h2>
    <div mat-dialog-content>
        <h1>
            {{ 'profile.delete_account.title' | translate }}
        </h1>
        <div class="description-1">
            {{ 'profile.delete_account.description_1' | translate }}
        </div>
        <div class="description-2">
            {{ 'profile.delete_account.description_2' | translate }}
        </div>
    </div>
    <div mat-dialog-actions class="dialog-buttons-end">
      <button class="close-ref cancel" mat-flat-button color="primary" [title]="'general.cancel' | translate | titlecase" (click)="close()">
        {{ 'general.cancel' | translate}}
      </button>
      <button class="close-ref confirm" mat-flat-button [title]="'general.delete' | translate | titlecase" (click)="delete()">
        {{ 'general.delete' | translate }}
      </button>
    </div>
</div>